import React from 'react'
import {graphql} from 'gatsby'
import * as styles from '../styles/StansonHealth.module.scss'
import ASHeader from '../components/AppliedSciences/ASHeader/ASHeader'
import ASContent from '../components/AppliedSciences/ASContent/ASContent'
import MarketoContactForm from '../components/ContactForm/MarketoContactForm'
import Layout from '../components/Layout/Layout'
import { Script } from "gatsby"
import Seo from '../components/Seo/Seo'

export const query = graphql`
query stansonHealthPages {
  allSanityStansonHealth {
    nodes {
      slug {
        current
      }
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      marketoFormID
      headerImageBlock {
        header
        pageCategoryName
        _rawText
        image {
          asset {
            url
          }
        }
        altText
        button {
          buttonText
          buttonData
          buttonContext
          buttonFile {
            asset {
              url
              title
            }
          }
        }
        announcement {
          showElement
          title
          button {
            buttonText
            buttonData
            buttonContext
            buttonFile {
              asset {
                url
                title
              }
            }
          }
          image {
            asset {
              url
            }
          }
          altText
        }
      }
      content {
        ... on SanityYellowBlock {
          _type
          showElement
          blockContext
          _rawTextContent
          footnoteText
          _rawFootnote
          _rawBoldText
        }
        ... on SanityPartnerSection {
          _type
          showElement
          title
          columns
          partnerLogos {
            asset {
              url
            }
          }
        }
        ... on SanityColoredBlock {
          _type
          showElement
          title
          _rawDescription
          button {
            buttonText
            buttonData
            buttonContext
            buttonFile {
              asset {
                url
              }
            }
          }
        }
        ... on SanityBillboard {
          _type
          showElement
          title
          _rawDescription
          maxColumns
          _rawFootnote
          backgroundImage {
            asset {
              url
            }
          }
          altText
          posts {
            ... on SanityPost {
              _type
              showElement
              title
              lineBreak
              _rawDescription
              icon {
                asset {
                  url
                }
              }
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
            ... on SanityWhitePaper {
              _type
              showElement
              title
              _rawDescription
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
            ... on SanityResource {
              _type
              showElement
              title
              _rawDescription
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
          }
          billboardType
        }
        ... on SanityAddInfo {
          _type
          showElement
          headerImage {
            asset {
              url
              gatsbyImageData
            }
          }
          caseStudies {
            showElement
            title
            bodyText
            backgroundImage {
              asset {
                gatsbyImageData
              }
            }
            link
          }
          infoArr {
            showElement
            title
            description
            ctaTitle
            ctaRoute
            ctaFile {
              asset {
                url
              }
            }
          }
        }
        ... on SanityHeroBlock {
          _type
          showElement
          title
          _rawDescription
          image {
            asset {
              url
              gatsbyImageData
            }
          }
          buttonOptions {
            buttonText
            buttonData
            buttonContext
            buttonFile {
              asset {
                url
              }
            }
          }
        }
        ... on SanityContentBlock {
          _type
          showElement
          logo {
            asset {
              url
            }
          }
          altLogoText
          title
          _rawSubtitle
          _type
          popOut
          image {
            asset {
              url
              gatsbyImageData
            }
          }
          altText
          blocks {
            ... on SanityVideoBlock {
              _type
              title
              description {
                _rawTextEditor
              }
              videoLink
              anchorLink
            }
            ... on SanityLinkBlock {
              _type
              showElement
              title
              image {
                asset {
                  url
                  gatsbyImageData
                }
              }
              altText
              imageLink
              _rawDescription
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
              _rawFootnote
            }
            ... on SanityNewsArticle {
              _type
              showElement
              title
              _rawSubtitle
              alignment
              _rawArticle
              image {
                asset {
                  url
                }
              }
              altText
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
              _rawFootnote
            }
            ... on SanityFeatureList {
              _type
              showElement
              maxColumns
              features {
                showElement
                title
                _rawDescription(resolveReferences: {maxDepth: 2})
                variant
                button {
                  buttonText
                  buttonData
                  buttonContext
                  buttonFile {
                    asset {
                      url
                    }
                  }
                }
                footnoteText
                _rawFootnote
              }
              title
              _rawDescription
            }
            ... on SanityNewsList {
              _type
              showElement
              newsArticles {
                showElement
                title
                slug {
                  current
                }
                _rawSubtitle
                alignment
                _rawArticle
                image {
                  asset {
                    url
                  }
                }
                button {
                  buttonText
                  buttonData
                  buttonContext
                  buttonFile {
                    asset {
                      url
                    }
                  }
                }
                _rawFootnote
              }
            }
            ... on SanityYellowBlock {
              _type
              showElement
              _rawTextContent
              footnoteText
              _rawFootnote
              _rawBoldText
              blockContext
            }
            ... on SanityBillboard {
              _type
              showElement
              title
              _rawDescription
              maxColumns
              _rawFootnote
              posts {
                ... on SanityPost {
                  _type
                  showElement
                  title
                  lineBreak
                  icon {
                    asset {
                      url
                    }
                  }
                  altText
                  _rawDescription
                  button {
                    buttonText
                    buttonData
                    buttonContext
                    buttonFile {
                      asset {
                        url
                      }
                    }
                  }
                }
                ... on SanityWhitePaper {
                  showElement
                  title
                  _rawDescription
                  button {
                    buttonText
                    buttonData
                    buttonContext
                    buttonFile {
                      asset {
                        url
                      }
                    }
                  }
                  _type
                }
                ... on SanityResource {
                  _type
                  showElement
                  title
                  _rawDescription
                  button {
                    buttonText
                    buttonData
                    buttonContext
                    buttonFile {
                      asset {
                        url
                      }
                    }
                  }
                }
              }
              maxColumns
              backgroundImage {
                asset {
                  url
                }
              }
              altText
              _rawDescription
              billboardType
            }
            ... on SanityImageBlock {
              _type
              showElement
              header
              image {
                asset {
                  url
                }
              }
              altText
              imageLink
              mobileImage {
                asset {
                  url
                }
              }
              _rawCaption
            }
            ... on SanityTeamList {
              _type
              showElement
              title
              _rawDescription
              team
            }
            ... on SanityColoredBlock {
              _type
              showElement
              title
              _rawDescription
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
            ... on SanityColoredInfoBlock {
              _type
              showElement
              title
              _rawInfo
              _rawBullets
            }
            ... on SanityFeatureBlock {
              _type
              showElement
              title
              _rawDescription
              footnoteText
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
              _rawFootnote
            }
            ... on SanityHeroBlock {
              _type
              showElement
              title
              image {
                asset {
                  url
                  gatsbyImageData
                }
              }
              altText
              _rawDescription
              buttonOptions {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
            ... on SanityResourceList {
              _type
              showElement
              resources {
                title
                showElement
                _rawDescription
                button {
                  buttonText
                  buttonData
                  buttonContext
                  buttonFile {
                    asset {
                      url
                    }
                  }
                }
              }
            }
            ... on SanityItemPopoverSection {
              _type
              showElement
              title
              _rawDescription
              items {
                title
                subtitle
                showElement
                _rawDescription
                altText
                image {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
      }
      marketoFormHeader
      marketoFormSubheader
    }
  }
  allSanityTeamMember(sort: {fields: orderRank}) {
    nodes {
      showElement
      name
      orderRank
      profile {
        asset {
          url
        }
      }
      altText
      associatedTeams
      position
      degrees
      _rawBiography
      showcase {
        caseName
        _rawDescription
      }
    }
  }
}
  `

const StansonHealthTemplate = props => {
    const { data, pageContext } = props;
    let {stansonHealthSlug} = pageContext;
    // console.log(stansonHealthSlug.current);
    //slug conversion due to Sanity requiring unique slugs 
    if(stansonHealthSlug && stansonHealthSlug.current === 'success-stories') {
      stansonHealthSlug.current = '/success-stories';
    }
    const pageData = data.allSanityStansonHealth.nodes.find(stanson => 
      stanson.slug.current === stansonHealthSlug.current);
    let stansonData = pageData;
    stansonData.content.teamMembers = data.allSanityTeamMember.nodes;
    const headerClassname = stansonHealthSlug.current.includes('/team') ? 'positionRight' : null
    // console.log(pageData);
    
    return (
        <>
        <Layout>
          <main>
            <ASHeader data={stansonData.headerImageBlock} headerClassname={headerClassname} themeContext={'stansonHealth'} pageContext={stansonHealthSlug.current}/>
            <ASContent data={stansonData.content} pageContext={stansonHealthSlug.current} themeContext={'stansonHealth'}/>
            {stansonData.marketoFormID ?
              <div className={styles.formContainer}>
                <MarketoContactForm formID={"mktoForm_" + stansonData.marketoFormID} formCode={stansonData.marketoFormID} variant={'stanson'}
                heading={stansonData.marketoFormHeader || ""}
                subheading={stansonData.marketoFormSubheader || ""} />
              </div>
            :
            null
            }
          </main>
        </Layout>
        <Script src="/js/leadlander.js" />
        </>
    )
}

export default StansonHealthTemplate

export const Head = (props) => {
  const { data, pageContext } = props;
  let {stansonHealthSlug} = pageContext;
  const pageData = data.allSanityStansonHealth.nodes.find(stanson => 
    stanson.slug.current === stansonHealthSlug.current);
  return (
    <Seo title={pageData.browserTitle} seo={pageData.seoData} />
  )
}